<template>
  <div class="reset-password-container">
    <div class="container">
      <div class="d-flex align-items-center mb-3">
        <img src="@/assets/images/icon.svg" alt="RegAlytics Logo" width="40">
        <h3 class="ms-3">Password Reset</h3>
      </div>

      <div class="reset-card">
        <form @submit.prevent="handleSubmit">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input v-model="userEmail" id="email" class="form-control" type="email" required />
            <small>Use the email you used when signing up.</small>
          </div>
          <button class="btn btn-green mb-3" type="submit">Send Password Reset</button>
          <p class="small">Remember your password? <router-link to="/login">Login</router-link></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ResetPassword",

  data() {
    return {
      userEmail: ''
    }
  },

  methods: {
    ...mapActions('accounts', ['sendResetPasswordEmail']),

    handleSubmit() {
      const email = this.userEmail;

      if (email) {
        this.$store.dispatch('application/setApplicationLoadingState', true);
        this.sendResetPasswordEmail(email);
      }
    }
  }

}
</script>

<style scoped>
.reset-password-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.reset-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  padding: 2rem 2rem;
  overflow: hidden;
}
</style>
